import React from 'react'
import CookieConsent from 'react-cookie-consent'
import { Routes } from '..'

export const CookieConsentWrapper = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText={'Akceptuję'}
      cookieName="dobrobycie-cookies-consent"
      style={{ background: '#0E100F', fontFamily: 'Lato', fontSize: '1.2em' }}
      buttonStyle={{
        color: '#ffffff',
        backgroundColor: '#4C3B38',
        marginRight: '15px',
      }}
      expires={150}
    >
      Nasza strona używa plików cookie.{' '}
      <a href={Routes.PrivacyPolicy} target="_blank" rel="noreferrer">
        Zapoznaj się z polityką prywatności...
      </a>
    </CookieConsent>
  )
}
